import React from 'react'
import './PageNotFound.css'

function PageNotFound() {
  return (
    <div className='error-page'>
        <h2>404: Page Not Found</h2>
        
    </div>
  )
}

export default PageNotFound